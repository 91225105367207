import React, { useRef, useState } from 'react';
import { Alert } from 'react-bootstrap';
import emailjs from '@emailjs/browser';

export default function ContactForm() { 
  const form = useRef();
  const user_name = useRef();
  const user_email = useRef();
  const message = useRef();

  const [showSuccessAlert, setShowSuccessAlert] = useState(false);
  const [showErrorAlert, setShowErrorAlert] = useState(false);

  const sendEmail = (e) => {
    e.preventDefault();

    emailjs
      .sendForm(process.env.REACT_APP_SERVICE_ID, process.env.REACT_APP_TEMPLATE_ID, form.current, {
        publicKey: process.env.REACT_APP_PUBLIC_KEY,
      })
      .then(
        () => {
          setShowSuccessAlert(true);  
          setShowErrorAlert(false);
          form.current.user_name.value = '';
          form.current.user_email.value = '';
          form.current.message.value = '';
        },
        (error) => {
          setShowErrorAlert(true);
          setShowSuccessAlert(false);
        },
      );
  };

  return (
    <div className="container-flex offset-md-2" id='contactDiv'>
        {showSuccessAlert && <Alert variant="success">Thank you! We have received your message.</Alert>}
        {showErrorAlert && <Alert variant="danger">Oops! An error has happened. We'll get it fixed.</Alert>}
        <p>
            Thank you for your interest in MSJC Software. Please fill out the form below to contact us. We will get back to you as soon as possible. 
        </p>
        <form ref={form} onSubmit={sendEmail}>
            <div class="form-group">
                <label for="user_name">
                    Your name:
                    <input type="text" name="user_name" ref={user_name} class="form-control form-control-lg" placeholder="Enter your name" />
                </label>            
            </div>
            <div class="form-group">
                <label for="user_email" >
                    Your email address:
                    <input type="email" name="user_email" ref={user_email} class="form-control form-control-lg" placeholder="Enter your email address" />
                </label>
            </div>
            <div class="form-group">
                <label for="message">
                    Your message:
                    <textarea type="text" name="message" ref={message} class="form-control form-control-lg" placeholder="Enter your message" />
                </label>
            </div> 
            <button type="submit" class="btn btn-primary">Submit</button>
        </form>
    </div>
  );
};